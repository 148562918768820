<template>
  <!-- <v-container fluid>
    <v-toolbar prominent theme="ligth">
      <v-toolbar-title>{{ $t("probes") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog transition="dialog-bottom-transition" width="auto">
        <template v-slot:activator="{ props }">
          <v-btn
            variant="outlined"
            color="green"
            v-bind="props"
            @click="editItem(null)"
          >
            Create</v-btn
          >
        </template>
        <template v-slot:default="{ isActive }">
          <v-card>
            <v-toolbar color="success" title="Create Sensor"></v-toolbar>
            <v-card-text>
              <div class="text-h2 pa-12">IN DEVELOPPEMENT</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn variant="text" @click="isActive.value = false"
                >Cancel</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-toolbar>
  </v-container> -->

  <v-container fluid>
    <!-- {{ items[0] }} -->
    <!-- https://codepen.io/wolfpup/pen/gOadmPx -->

    <!-- <v-select
      v-model="headersSelected"
      :items="headers"
      label="Select"
      multiple
      hint="Pick your favorite states"
      persistent-hint
    ></v-select> -->
    <!-- {{ headersSelected }} -->
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="20"
      :loading="loading"
      :search="search"
      loading-text="Loading... Please wait"
      item-value="name"
      class="elevation-1"
    >
      <!-- <template v-slot:top>
        <v-toolbar>
          <v-toolbar-title>{{ $t("probes") }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template> -->

      <template v-slot:top>
        <v-toolbar color="green-lighten-5">
          <v-toolbar-title>{{ $t("probes") }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ props }">
              <v-btn dark class="mb-2" v-bind="props">Create</v-btn>
            </template>

            <v-card class="overflow-y-auto" max-width="700px">
              <v-card-title>
                <span class="text-h5">{{ formTitle() }}</span>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="_ID"
                        v-model="editedItem._id"
                        required
                        disabled
                        variant="outlined"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Name"
                        v-model="editedItem.name"
                        variant="outlined"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Stream"
                        v-model="editedItem.streamId"
                        persistent-hint
                        required
                        variant="outlined"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :items="unititems"
                        v-model="editedItem.unit"
                        item-title="abbr"
                        item-value="state"
                        label="Unit"
                        variant="outlined"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <small>*indicates required field</small>
                </v-card-text>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="dialog = false"> Cancel </v-btn>
                <v-btn color="green" text @click="updateSensorInfo(editedItem)">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Dialog de suppression d'une ligne du tableau -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5 overflow-y-auto"
                >Do yo want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon size="small" class="me-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon size="small" @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize"> Reset </v-btn> -->
        <v-progress-circular
          :size="70"
          :width="7"
          color="success"
          indeterminate
        ></v-progress-circular>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Axios from "@/_services/caller.service";
import Sensor from "../../components/sensor/Sensor.vue";
//import store from "@/store";
import { useStore } from "vuex";
import { computed, onBeforeMount, onMounted, ref } from "@vue/runtime-core";

export default {
  name: "SensorslistComponent",
  components: { Sensor },
  setup() {
    //let items = ref([]);
    const searchField = ref("nmae");
    const searchValue = ref("");

    const store = useStore();

    const getAll = async () => {
      await store.dispatch("sensorsStore/getAllSensors");
    };

    const loading = ref(true);
    const items = computed(() => {
      loading.value = false;
      return store.getters["sensorsStore/getSensors"];
    });

    onBeforeMount(async () => {
      await getAll();
    });

    const getDateTime = (timestamp) => {
      return new Date(timestamp * 1000).toLocaleString(); //toString();
    };

    const headers = ref([
      // { title: "ID", key: "_id" },
      { title: "STREAM", key: "streamId" },
      { title: "NAME", key: "name" },
      { title: "VALUE", key: "value" },
      { title: "UNIT", key: "unit" },
      //{ title: "ALERT", key: "alertConditionID" },
      //{ title: "USERS GR", key: "usersGroupIDs" },
      // { title: "ORG", key: "orgId.name", width: 200 },
      { title: "TIME", key: "timestamp" },
      { title: "ACTIONS", key: "actions" },
    ]);

    const itemsSelected = ref([]);

    /////
    let dialog = ref(false);
    let dialogDelete = ref(false);
    let editedItem = ref({
      _id: "",
      name: "",
      unit: "",
      streamId: "",
    });

    // TODO Deplacer vers le store
    const updateSensorInfo = async (editedItem) => {
      dialog.value = false;
      await Axios.put("/api/v1/sensors/" + editedItem.streamId, {
        //id: editedItem.sensorId,
        name: editedItem.name,
        unit: editedItem.unit,
        inactivityDelay: editedItem.inactivityDelay,
      })
        .then((res) => {
          //console.log(res);
        })
        .catch((error) => console.log(error));
    };

    let isEditedItem = ref(false);
    const formTitle = () => {
      return isEditedItem.value === true ? "Edit Sensor" : "Create Sensor";
    };

    const editItem = (item) => {
      isEditedItem.value = true;
      editedItem.value = Object.assign({}, item);
      dialog.value = true;
    };

    const deleteItem = async (item) => {
      dialogDelete.value = true;
      isEditedItem.value = true;
      editedItem.value = Object.assign({}, item);
    };

    const deleteItemConfirm = async () => {
      closeDelete();
      //console.log("===> ATTTA <======");
      //console.log(editedItem.value._id);
      // TODO Ceci cree un crash au niveau du serveur
      await Axios.delete("/api/v1/sensors/" + editedItem.value._id);
    };

    const closeDelete = () => {
      dialogDelete.value = false;
    };

    let search = ref("");
    const searchColumn = (column) => {
      search.value = `${column}:${search}`;
    };

    let headersSelected = ref([]);

    const unititems = [
      // { state: "Temperature", abbr: "˚C" },
      // { state: "Humidity", abbr: "% HR" },
      // { state: "Pressure", abbr: "hPa" },
      { state: "HUM", abbr: "%RH" },
      { state: "TEMP", abbr: "˚C" },
      { state: "PRES", abbr: "hPa" },
      { state: "CO2", abbr: "%" },
      { state: "PPM", abbr: "ppm" },
    ];

    /////

    return {
      headers,
      items,
      itemsSelected,
      loading,

      dialog,
      dialogDelete,
      editedItem,
      deleteItem,
      deleteItemConfirm,
      editItem,
      updateSensorInfo,
      closeDelete,

      searchField,
      searchValue,
      getDateTime,

      formTitle,
      searchColumn,
      headersSelected,
      search,
      unititems,
    };
  },
};
</script>

<style>
/* .customize-table {
    --easy-table-header-font-size: 20px;
    --easy-table-body-row-font-size: 18px;
  } */
</style>

import { createRouter, createWebHistory } from "vue-router";
import Layout from "../components/layout/Layout.vue";
import Alerts from "../views/alerts/Alerts.vue";
import Login from "../views/login/Login.vue";
import ResetPasswordLink from "../views/login/ResetPasswordLink.vue";
import ResetPassword from "../views/login/ResetPassword.vue";
import Signup from "../views/signup/Signup.vue";
import Charts from "../views/charts/Charts.vue";
import Notifications from "../views/notifications/Notifications.vue";
import CreateNotification from "../views/notifications/notificationcreate/CreateNotification.vue";
import Users from "../views/users/Users.vue";
import CreateUser from "../views/users/createuser/CreateUser.vue";
import Update from "../views/update/Update.vue";
import Dashboard from "../views/dashboard/Dashboard.vue";
import ErrorView from "../views/error/ErrorView.vue";
import Streams from "../views/streams/Streams.vue";
import Firmwares from "../views/firmware/firmware.vue";

import BaniriDatatable from "../components/baniriDatatable/BaniriDatatable.vue";

import AccountSetting from "../views/pages/account-setting/AccountSettings.vue";

import CreateSensors from "../views/pages/create-sensors/CreateSensors.vue";
import OrganizationsSettings from "../views/pages/organizations-settings/OrganizationsSettings.vue";
import OrgsManager from "../views/pages/orgs-manager/OrgsManager.vue";
import UsersManager from "../views/pages/users-manager/UsersManager.vue";

import SensorsList from "../views/sensors/sensorsList.vue";
import SensorsGroup from "../views/sensors/sensorsGroup.vue";
import StreamsList from "../views/streams/StreamsList.vue";
import DevicesList from "../views/devices/devicesList.vue";

import { useStore } from "vuex";

const store = useStore();
const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/resetpasswordlink",
    name: "resetpasswordlink",
    component: ResetPasswordLink,
  },
  {
    path: "/passwordreset/:userId/:token",
    name: "passwordreset",
    component: ResetPassword,
  },
  {
    path: "/",
    redirect: "login",
    name: "Layout",
    component: Layout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: { isRequered: true },
      },

      {
        path: "/account-settings",
        name: "pages-account-settings",
        component: AccountSetting,
        meta: { isRequered: true },
      },
      {
        path: "/create-sensors",
        name: "pages-create-sensors",
        component: CreateSensors,
        meta: { isRequered: true },
      },
      {
        path: "/organizations-settings",
        name: "pages-organizations-settings",
        component: OrganizationsSettings,
        meta: { isRequered: true },
      },
      {
        path: "/orgs-manager",
        name: "pages-orgs-manager",
        component: OrgsManager,
        meta: { isRequered: true },
      },
      {
        path: "/users-manager",
        name: "pages-users-manager",
        component: UsersManager,
        // beforeEnter: (to, from, next) => {
        //   if (store.state.sessionStore.userEmail == "modi@favi.io") {
        //     next(false);
        //   } else {
        //     next();
        //   }
        // },
      },
      {
        path: "/streams",
        name: "streams",
        component: StreamsList,
        meta: { isRequered: true },
      },
      {
        path: "/devices",
        name: "devices",
        component: DevicesList,
        meta: { isRequered: true },
      },
      {
        path: "/sensors",
        name: "sensors",
        component: SensorsList,
        meta: { isRequered: true },
      },
      {
        path: "/sensorsGroup",
        name: "sensorsGroup",
        component: SensorsGroup,
        meta: { isRequered: true },
      },
      {
        path: "/charts",
        name: "charts",
        component: Charts,
        meta: { isRequered: true },
      },
      {
        path: "/alerts",
        name: "aLerts",
        component: Alerts,
        meta: { isRequered: true },
      },
      {
        path: "/notifications",
        name: "notifications",
        component: Notifications,
        meta: { isRequered: true },
      },
      {
        path: "/notificationcreate",
        name: "createNotification",
        component: CreateNotification,
        meta: { isRequered: true },
      },
      {
        path: "/users",
        name: "users",
        component: Users,
        meta: { isRequered: true },
      },
      {
        path: "/createuser",
        name: "createUser",
        component: CreateUser,
        meta: { isRequered: true },
      },
      {
        path: "/update",
        name: "update",
        component: Update,
        meta: { isRequered: true },
      },
      {
        path: "/firmwares",
        name: "firmwares",
        component: Firmwares,
        meta: { isRequered: true },
      },
      {
        path: "/baniridatable",
        name: "baniridatable",
        component: BaniriDatatable,
        meta: { isRequered: true },
      },
      {
        path: "/:pathMach(.*)*",
        name: "Error",
        component: ErrorView,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//https://vueschool.io/lessons/how-to-configure-an-authentication-middleware-route-guard-with-vue-router
router.beforeEach((to, from, next) => {
  //console.log("GOBAL Before Guards");
  if (to.matched.some((record) => record.meta.isRequered)) {
    let isAuthenticated = localStorage.getItem("login"); // TODO Utiliser redis
    if (to.name !== "Login" && !isAuthenticated) next({ name: "Login" });
    else next();
  } else next();
});

// router.beforeEach((to, from, next) => {
//   console.log("GOBAL Before Guards");
//   let isAuthenticated = localStorage.getItem("login");
//   if (to.name !== "Login" && !isAuthenticated) next({ name: "Login" });
//   else next();
// });

export default router;

import router from "../../router/index";
import axios from "axios";
import Axios from "../../_services/caller.service";

const urlUserApi = process.env.VUE_APP_URL;
// const AxiosUser = axios.create({
//   baseURL: urlUserApi,
// });
// axios.defaults.withCredentials = true;
// const axiosConfig = {
//   timeout: 5000,
//   withCredentials: true,
//   headers: {
//     "Access-Control-Allow-Origin": "*",
//     "Content-Type": "application/json",
//     "X-Requested-With": "Axios",
//   },
// };

// initial state
const state = {
  user: null,
  userId: null,
  token: null, //localStorage.setItem("token") || null,
  sensors: null,
  status: "",
  usersOfOrg: [],
  orgsOfUser: [], // using
  currentOrg: "", // using
  oserOrg: "",
  userEmail: "",
  userProfile: "",
  drawer: true,
  roleAdmin: false,
  message: "",
};

// getters
const getters = {
  token: (state) => state.token,
  user: (state) => state.user,
  organizations: (state) => state.organizations,
  getLogin(state) {
    return typeof state.token === "string" && state.token !== "";
  },
  getToken(state) {
    return state.token;
  },
  getUserId(state) {
    return state.userId;
  },
  getUserType(state) {
    return state.userType;
  },

  getProfile(state) {
    return state.userProfile;
  },

  getUsersOfOrg(state) {
    return state.usersOfOrg;
  },

  getUserSession(state) {
    return state.session;
  },
};

const fetch = async (context, email, access_token, callBackFunction) => {
  Axios.get("/api/user/org/" + email).then(
    (res) => {
      //console.log(res.data);
      localStorage.setItem("login", true);
      localStorage.setItem("organization", res.data[0].name);
      localStorage.setItem("user", email);
      localStorage.setItem("authenticated", true);
      //AxiosUser.defaults.headers.common["Authorization"] = access_token;
      const userData = {
        userId: "1",
        token: access_token,
        userOrg: res.data,
        userEmail: email,
      };
      context.commit("setUserId", "6788");
      context.commit("setUser", userData);

      const userSession = {
        userId: "1",
        userEmail: email,
        userType: "1",
        oserOrg: res.data[0].name,
        organizationId: res.data[0].id,
        organizations: res.data,
        token: access_token,
        drawer: true,
      };

      context.commit("setSession", userSession);

      router.push("/Dashboard");
      callBackFunction();
    },
    (err) => {
      console.log(err);
      //console.log("Server indisponible");
    }
  );
};

// actions
const actions = {
  loginSession(context, data) {
    sessionStorage.setItem("session", JSON.stringify(data));
    context.commit("setSession", data);
  },

  getSession(context) {
    const session = sessionStorage.getItem("session");
    if (session && typeof session === "string" && session !== "") {
      const data = JSON.parse(session);
      context.commit("setSession", data);
    }
  },

  logoutSession(context) {
    context.commit("logoutSession");
  },

  // async logout(context) {
  //   context.commit("setUserId", "");
  //   localStorage.clear();
  //   AxiosUser.defaults.headers.common["Authorization"] = "";
  //   router.push("/login");
  // },

  async resetPasswordLink(context, email) {
    console.log("Resetting password");
    console.log(email);
    let value = "";
    await Axios.post("/api/v1/users/passwordresetlink", email)
      .then((response) => {
        console.log(response);
        value = "success";
      })
      .catch((err) => {
        console.log(err);
        value = "error";
      });

    context.commit("setResponse", value);
  },

  async resetPassword(context, resetInfo) {
    let value = "";
    await Axios.post("/api/v1/users/passwordreset", resetInfo)
      .then((response) => {
        console.log(response);
        value = "success";
      })
      .catch((err) => {
        console.log(err);
        value = "error";
      });

    context.commit("setResponse", value);
  },

  async login(context, { email, password }) {
    let message = "";
    await Axios.post("/api/v1/auth/login", {
      email: email,
      password: password,
    })
      .then((res) => {
        //console.log(res.data);

        const access_token = res.data.access_token;
        //console.log(access_token);
        localStorage.setItem("login", true);
        localStorage.setItem("user", email);
        localStorage.setItem("jwt", access_token);
        localStorage.setItem("login", true);
        localStorage.setItem("organization", res.data.orgsId[0].name);
        localStorage.setItem("authenticated", true);

        const userData = {
          userId: res.data.usersId,
          token: access_token,
          userOrg: res.data.orgsId.name,
          userEmail: email,
        };
        context.commit("setUserId", "6788");
        context.commit("setUser", userData);

        const userSession = {
          status: res.data.status,
          userId: "1",
          userEmail: email,
          userType: "1",
          organizationId: res.data.orgsId[0]._id,
          organizations: res.data.orgsId,
          currentOrg: res.data.orgId,
          preferencies: res.data.preferencies,
          token: access_token,
          drawer: true,
        };

        const softVersion = res.data.softVersion;

        message = "success";

        //console.log(res);

        // axios.defaults.headers.cookie = res.headers["set-cookie"];
        //console.log(res.cookies);

        let orgsArray = [];
        res.data.orgsId.forEach((element) => {
          orgsArray.push(element.name);
        });

        localStorage.setItem("organizations", JSON.stringify(orgsArray));
        localStorage.setItem("pref", JSON.stringify(userSession.preferencies));
        localStorage.setItem("ver", softVersion);
        // localStorage.setItem(
        //   "organizationss",
        //   JSON.stringify({ email: email, orgs: res.data })
        // );

        context.commit("setSession", userSession);
        router.push("/dashboard");
      })
      .catch((error) => {
        const userSession = {
          status: "",
          userId: "",
          userEmail: "",
          userType: "",
          organizationId: "",
          organizations: "",
          token: "",
          drawer: false,
          message: error.response.data.message,
        };

        message = "error";
        context.commit("setSession", userSession);
        console.log(error.response.data.message);
        console.log(error);
      });

    context.commit("setResponse", message);
  },

  // https://axios-http.com/docs/urlencoded

  async createUser(
    context,
    {
      firstName,
      lastName,
      name,
      email,
      phoneNumber,
      organization,
      role,
      password,
    }
  ) {
    //console.log(firstName.value);
    const user = {
      firstName: firstName.value,
      lastName: lastName.value,
      username: email.value,
      name: name.value,
      email: email.value,
      phoneNumber: phoneNumber.value,
      organizations: organization.value,
      roles: role.value,
      password: password.value,
    };
    //console.log(user);
    await Axios.post("/api/v1/users/save", user).then((res) => {
      //console.log(res.data);
    });
    context.commit();
  },

  async getAllUsersOfOrg(context, orgID) {
    console.log("Get all users for organisation " + orgID);
    await Axios.get("/api/v1/users/usersoforg/" + orgID)
      .then((resp) => {
        // console.log(resp.data);

        let orgsArray = [];
        resp.data.forEach((element) => {
          orgsArray.push(element.email);
        });

        //console.log(orgsArray);
        context.commit("setUsersOfOrg", orgsArray);
      })
      .catch((err) => console.log(err.message));
  },

  async updateUsersCurrentOrgId(context, id) {
    console.log("Update user's organization Id: " + id);
    await Axios.put("/api/v1/users/usersoforg/" + id)
      .then((resp) => {
        //console.log(resp.data);
        context.setCurrentOrg(id);
      })
      .catch((err) => console.log(err.message));
  },

  async getAllOrgsOfUser(context) {
    await Axios.get("/api/v1/users/orgsofuser/")
      .then((resp) => {
        // console.log(resp.data);
        context.commit("setOrgsOfUser", resp.data.orgsId);
        context.commit("setCurrentOrg", resp.data.orgId);
      })
      .catch((err) => console.log(err.message));
  },

  async changePreferences(context, data) {
    //console.log(JSON.stringify(data));
    Axios.put("/api/v1/users/uipreferencies/" + JSON.stringify(data));
  },

  async deleteUser(
    context,
    {
      firstName,
      lastName,
      name,
      email,
      phoneNumber,
      organization,
      role,
      password,
    }
  ) {},
};

// mutations
const mutations = {
  setUsersOfOrg(state, payload) {
    state.usersOfOrg = payload;
  },

  setOrgsOfUser(state, payload) {
    state.orgsOfUser = payload;
  },

  setCurrentOrg(state, payload) {
    state.currentOrg = payload;
  },

  setUser(state, payload) {
    state.user = payload;
    state.userId = payload.userId;
    state.token = payload.token;
    state.organizations = payload.userOrg;
    state.userEmail = payload.userEmail;
  },

  setUserId(state, payload) {
    state.userId = payload;
  },

  setSession(state, payload) {
    // state.token = n.token;
    // state.userid = n.userid;
    // state.user_type = n.user_type;

    state.session = payload;
    state.user = payload;
    state.userId = payload.userId;
    state.userEmail = payload.userEmail;
    state.userType = payload.userType;
    state.organizations = payload.organizations;
    state.oserOrg = payload.userOrg;
    state.token = payload.token;
    state.userProfile = {
      email: payload.userEmail,
      organizations: payload.organizations,
    };
    state.drawer = true;
    state.currentOrg = payload.orgId;
    state.preferencies = payload.preferencies;
  },

  setResponse(state, payload) {
    state.message = payload;
  },

  async logoutSession(state) {
    sessionStorage.clear();
    localStorage.clear();
    //AxiosUser.defaults.headers.common["Authorization"] = "";
    // state.token = "";
    // state.userid = "";
    // state.user_type = 0;
    state.user = null;
    state.userId = null;
    state.userEmail = "";
    state.userType = "";
    state.oserOrg = "";
    state.token = null; //localStorage.setItem("token") || null,
    state.sensors = null;
    state.status = "";
    state.drawer = true;

    await Axios.get("/api/v1/auth/logout").then(() => {});

    router.push("/login");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <!-- <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="500px">
    <v-btn color="primary" dark slot="activator">Open Dialog</v-btn>
    <v-card>
      <v-card-title>
        <span class="headline">User Profile</span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm6 md4>
              <v-text-field label="Legal first name" required></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-text-field
                label="Legal middle name"
                hint="example of helper text only on focus"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-text-field
                label="Legal last name"
                hint="example of persistent helper text"
                persistent-hint
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field label="Email" required></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                label="Password"
                type="password"
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-select
                label="Age"
                required
                :items="['0-17', '18-29', '30-54', '54+']"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6>
              <v-select
                label="Interests"
                multiple
                autocomplete
                chips
                :items="[
                  'Skiing',
                  'Ice hockey',
                  'Soccer',
                  'Basketball',
                  'Hockey',
                  'Reading',
                  'Writing',
                  'Coding',
                  'Basejump',
                ]"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" flat @click.native="dialog = false"
          >Cancel</v-btn
        >
        <v-btn color="blue darken-1" flat @click.native="dialog = false"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
    </v-dialog>
  </v-layout> -->

  <v-container fluid v-if="dialog">
    <v-card>
      <v-card-title>
        <span class="text-h5">Create user</span>
      </v-card-title>
      <v-card-text>
        <!-- {{ userData }} -->
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="First name*"
              v-model="userData.lastName"
              required
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Last name"
              v-model="userData.firstName"
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Username*"
              v-model="userData.username"
              persistent-hint
              required
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Email*"
              v-model="userData.email"
              required
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Phone"
              v-model="userData.phone"
              required
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col v-if="false" cols="12">
            <v-text-field
              label="Password*"
              type="password"
              v-model="password"
              required
              variant="outlined"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="rolesList"
              v-model="userData.role"
              label="Roles*"
              item-title="name"
              item-value="_id"
              multiple
              required
              variant="outlined"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="orgsList"
              v-model="userData.orgsId[0].name"
              label="Organizations"
              item-title="name"
              item-value="id"
              multiple
              required
              variant="outlined"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!-- </v-container> -->
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="close"> Cancel </v-btn>
        <v-btn color="green" text @click="createUser"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { ref, onMounted } from "vue";
export default {
  props: {
    userData: {
      lastName: String,
      phone: String,
      role: ["yytst", "rerert"],
    },
  },
  setup(props) {
    let userDataa = ref({
      // lastName: String,
      // phone: String,
      // roles: String,
    });

    let dialog = ref(false);
    const close = () => {
      dialog.value = false;
    };

    onMounted(() => {
      dialog.value = true;
    });

    return { userDataa, close, dialog };
  },
};
</script>

<style></style>

<template>
  <v-container>
    <v-card>
      <v-card-title class="align-start">Device settings </v-card-title>
      <!-- {{ orgsList }} -->
      <!-- {{ deviceInfo }} -->
      <v-card-text>
        <v-form class="multi-col-validation">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="deviceName"
                label="Name"
                outlined
                dense
                placeholder="Name"
                hide-details
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                :items="orgsList"
                v-model="deviceOrgId"
                label="Organization"
                item-title="name"
                item-value="_id"
                required
              ></v-autocomplete>
            </v-col>
            <v-col v-if="resquestMsg" cols="12" sm="6">
              <span class="text-red-lighten-1 text-h5">{{ resquestMsg }}</span>
            </v-col>
            <v-col cols="12">
              <v-btn color="success" @click="update"> Save </v-btn>
              <v-btn color="warning" type="reset" outlined class="mx-2">
                Clear
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { onBeforeMount, onMounted, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import Axios from "@/_services/caller.service";

export default {
  name: "device_setting",
  props: ["deviceInfo"],
  setup(props) {
    let resquestMsg = ref("");
    const store = useStore();
    onMounted(() => {
      fetchOrg();
    });

    const fetchOrg = async () => {
      await store.dispatch("organizationStore/getAllOrgs");
      orgsList.value = store.state.organizationStore.organizations;
    };

    // All organization
    const orgsList = ref();
    const deviceOrgId = ref(); //ref(props.deviceInfo.orgId._id);
    const deviceName = ref(); //ref(props.deviceInfo.name);

    onMounted(() => {
      deviceOrgId.value = props.deviceInfo.orgId._id;
      deviceName.value = props.deviceInfo.name;
    });

    // if(props.deviceInfo.orgId._id) {
    //   deviceOrgId
    // }

    const update = async () => {
      //console.log("========> " + deviceOrgId.value);
      if (!deviceOrgId.value || !deviceName.value) {
        resquestMsg.value = "Fields are empty";
        return;
      }

      await Axios.put("/api/v1/devices/" + props.deviceInfo._id, {
        deviceName: deviceName.value,
        currentOrgId: deviceOrgId.value,
      })
        .then((res) => {
          console.log(res);
          resquestMsg.value = "Update successfully";
        })
        .catch((error) => console.log(error));

      //Update sensor orgId
      await Axios.put("/api/v1/sensors/orgs/" + props.deviceInfo.mac, {
        currentOrgId: deviceOrgId.value,
      })
        .then((res) => {
          console.log(res);
          resquestMsg.value = "Update successfully";
        })
        .catch((error) => console.log(error));

      // Update stream orgId
      await Axios.put("/api/v1/streams/orgs/" + props.deviceInfo.mac, {
        currentOrgId: deviceOrgId.value,
      })
        .then((res) => {
          console.log(res);
          resquestMsg.value = "Update successfully";
        })
        .catch((error) => console.log(error));
    };

    return {
      orgsList,
      deviceOrgId,
      deviceName,
      resquestMsg,
      update,
    };
  },
};
</script>

<style></style>

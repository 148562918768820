<template>
  <!-- <h1>{{ title }}</h1> -->
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      :loading="loading"
      loading-text="Loading... Please wait"
      item-value="name"
      show-select
      show-expand
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="green-lighten-5">
          <v-toolbar-title>Users</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ props }">
              <v-btn dark class="mb-2" v-bind="props"> Create </v-btn>
            </template>

            <!-- <UsersCreate :userData="editedItem"></UsersCreate> -->

            <v-card class="overflow-y-auto" max-width="700px">
              <!-- {{ editedItem }} -->
              <v-card-title>
                <span class="text-h5">{{ formTitle() }}</span>
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      label="First name*"
                      v-model="editedItem.lastName"
                      required
                      variant="outlined"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      label="Last name"
                      v-model="editedItem.firstName"
                      variant="outlined"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Username*"
                      v-model="editedItem.username"
                      persistent-hint
                      required
                      variant="outlined"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="!isEditedItem" cols="12">
                    <v-text-field
                      label="Password*"
                      type="password"
                      v-model="editedItem.password"
                      required
                      variant="outlined"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Email*"
                      v-model="editedItem.email"
                      required
                      variant="outlined"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Phone"
                      v-model="editedItem.phone"
                      required
                      variant="outlined"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      :items="rolesList"
                      v-model="editedItem.role"
                      label="Roles*"
                      item-title="name"
                      item-value="_id"
                      multiple
                      required
                      variant="outlined"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      :items="orgsList"
                      v-model="editedItem.orgsId"
                      label="Organizations"
                      item-title="name"
                      item-value="id"
                      multiple
                      required
                      variant="outlined"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <!-- </v-container> -->
                <small>*indicates required field</small>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" variant="text" @click="close">
                  Cancel
                </v-btn>
                <v-btn color="green" variant="text" @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Delete user -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Dialog de suppression d'une ligne du tableau -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5 overflow-y-auto"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <!-- Les bouton d'action sur chaque ligne du tableau -->
      <template v-slot:item.actions="{ item }">
        <v-icon size="small" class="me-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon size="small" @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>

      <!-- Spinner de chargement du tableau -->
      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize"> Reset </v-btn> -->
        <v-progress-circular
          :size="70"
          :width="7"
          color="success"
          indeterminate
        ></v-progress-circular>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { onBeforeMount, computed, ref, onMounted } from "vue";
import Axios from "@/_services/caller.service";
import UsersCreate from "./UsersCreate.vue";
import { useStore } from "vuex";

export default {
  components: { UsersCreate },
  setup(props) {
    const title = "Users list";

    const headers = [
      // { title: "ID", key: "_id" },
      { title: "FIRST NAME", key: "firstName" },
      { title: "LAST NAME", key: "lastName" },
      { title: "EMAIL", key: "email" },
      { title: "PHONE", key: "phone" },
      { title: "ORGS", key: "organizations" },
      { title: "ACTIONS", key: "actions" },
    ];

    let items = ref([]);
    const fetchUsers = async () => {
      await Axios.post("/api/v1/users", { role: "admin" })
        .then((res) => {
          items.value = res.data;
          res.data.forEach((user) => {
            if (user.organizations != undefined) {
              user.orgsId.forEach((org) => user.organizations.push(org.name));
            }
          });
        })
        .catch((error) => console.log(error));
    };

    const rolesList = ref();
    const getRolesList = async () => {
      await Axios.get("/api/v1/roles")
        .then((res) => {
          rolesList.value = res.data;
        })
        .catch((error) => console.log(error));
    };

    const orgsList = ref();
    const getOrgsList = async () => {
      await Axios.get("/api/v1/orgs")
        .then((res) => {
          orgsList.value = res.data;
        })
        .catch((error) => console.log(error));
    };

    onBeforeMount(() => {
      getRolesList();
      getOrgsList();
      fetchUsers();
    });

    let isEditedItem = ref(false);
    const formTitle = () => {
      return isEditedItem.value === true ? "Edit User" : "Create User";
    };

    let editedItem = ref({
      name: "organ",
      description: "Descrip",
    });

    const dialog = ref(false);
    const dialogDelete = ref(false);
    const loading = ref(false);

    const editItem = (item) => {
      isEditedItem.value = true;
      editedItem.value = Object.assign({}, item);
      dialog.value = true;
    };

    const deleteItem = async (item) => {
      dialogDelete.value = true;
      isEditedItem.value = true;
      editedItem.value = Object.assign({}, item);
    };

    const deleteItemConfirm = async () => {
      closeDelete();
      await Axios.delete("/api/v1/users/" + editedItem.value._id)
        .then((res) => {
          getRolesList();
          getOrgsList();
          fetchUsers();
        })
        .catch((error) => {
          alert("Vous n'ete pas autorise");
        });
    };

    const close = () => {
      dialog.value = false;
      isEditedItem.value = false;
    };

    const save = () => {
      close();
    };

    const closeDelete = () => {
      dialogDelete.value = false;
    };

    onMounted(() => {});

    return {
      title,

      // Data table info
      headers,
      items,
      dialogDelete,
      dialog,
      formTitle,
      save,
      close,
      closeDelete,
      editItem,
      deleteItem,
      deleteItemConfirm,

      editedItem,
      loading,
      isEditedItem,

      rolesList,
      orgsList,
    };
  },
};
</script>

<style></style>

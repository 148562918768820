<template>
  <!-- Display if niew version occure -->
  <v-row v-if="besoinRafraichir">
    <v-container fluid>
      <div class="mx-auto float-right info-banner">
        <v-alert
          density="compact"
          type="warning"
          title="Alert recharger la page"
          variant="outlined"
          prominent
          border="top"
          text="Une mise à jour est disponible. Veuillez rafraîchir la page pour
        l'appliquer."
        ></v-alert>
        <button @click="rafraichirPage">Rafraîchir</button>
      </div>
    </v-container>
  </v-row>

  <!-- All sensors -->
  <v-container fluid>
    <h3>Dashboard</h3>
    <v-sheet class="d-flex align-content-start flex-wrap">
      <Sensor
        v-for="(sensor, i) in sensors"
        :key="i"
        class="mx-auto mx-md-4 my-4"
        :sensorData="sensor"
      />
    </v-sheet>
  </v-container>

  <!-- Color guide -->
  <Guides />
</template>

<script>
import { computed, onMounted, onUnmounted, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";

import Sensor from "../../components/sensor/Sensor.vue";
import Guides from "./Guides";

export default {
  name: "DashboardComponent",
  components: { Sensor, Guides },
  setup() {
    const devman = false;
    let orgSelected = ref("");
    let organizations = [];
    let orgNbr = ref(false);
    let besoinRafraichir = ref(false);

    const store = useStore();

    const rafraichirPage = () => {
      location.reload();
      besoinRafraichir.value = false;
    };

    const onOrgChange = () => {
      localStorage.setItem("organization", orgSelected.value);
      fetchIotNodes();
    };

    // Get sensors current information
    const fetchIotNodes = async () => {
      try {
        await store.dispatch(
          "getSensorsForUserOrg",
          localStorage.getItem("organization")
        );
      } catch (err) {
        console.log(err);
      }
    };

    const fetchSoftVersion = async () => {
      try {
        await store.dispatch("fetchVersion");
        const version = store.state.version;
        //console.log("Current version", version.version);
        const current_version = localStorage.getItem("ver");
        if (current_version != version.version) {
          //console.log("New software version");
          localStorage.setItem("ver", version.version);
          rafraichirPage();
        }
      } catch (error) {
        console.log(error);
      }
    };

    // Refresh dashbord page
    function callbackFunc() {
      //console.log("Time to get data node");
      fetchIotNodes();
      fetchSoftVersion();
    }

    const timeoutObj = setInterval(callbackFunc, 5000);

    onBeforeMount(async () => {
      //console.log("Dashboard: before mount");

      const orgs = localStorage.getItem("organizations");
      //orgSelected.value = JSON.parse(orgs)[0];
      orgSelected.value = localStorage.getItem("organization");
      JSON.parse(orgs).forEach((element) => {
        organizations.push(element);
      });
      orgNbr.value = JSON.parse(orgs).length > 1;
      fetchIotNodes();
    });

    onUnmounted(() => {
      clearInterval(timeoutObj);
    });

    const sensors = computed(() => {
      return store.getters.getSensorsInfo;
    });

    onMounted(() => {
      //console.log("Dashboard: monted");
    });

    return {
      sensors,
      organizations,
      orgSelected,
      orgNbr,
      besoinRafraichir,
      onOrgChange,
      fetchIotNodes,
      rafraichirPage,
      devman,
    };
  },
};
</script>

<style lang="scss">
@import "Dashboard.scss";
</style>

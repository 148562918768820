<template>
  <!-- <div class="mt-10">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="searchValue"
                append-icon="mdi-magnify"
                label="rechercher"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </div> -->
  <!-- {{ items[0] }}
    {{ items[1] }} -->
  <!-- <my-v-data-table :headers="headers2" :items="items2"></my-v-data-table> -->
  <v-container fluid>
    <my-v-data-table
      :headers="headers"
      :items="items"
      :bname="$t('devices')"
      :filtrable="false"
      :searchable="false"
    >
      <template v-slot:top>
        <v-toolbar>
          <v-toolbar-title>{{ $t("devices") }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialogCreate" max-width="500px">
            <template v-slot:activator="{ props }">
              <v-btn dark class="mb-2" v-bind="props">Create</v-btn>
            </template>
            <v-card class="overflow-y-auto" max-width="700px">
              <v-card-title>
                <span class="text-h5">{{ formTitle() }}</span>
              </v-card-title>

              <v-card-text>
                <span class="display-block">Create transmitter</span>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" variant="text" @click="close">
                  Cancel
                </v-btn>
                <v-btn color="green" variant="text" @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <template>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this users?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green-darken-1"
                    variant="text"
                    @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="green-darken-1"
                    variant="text"
                    @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-toolbar>

        <v-dialog
          v-model="dialog"
          fullscreen
          :scrim="false"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="success">
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title class="text-h4"
                >Setting Transmitter {{ editedItem.mac }}</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn variant="text" class="text-h4" @click="dialog = false">
                  Save
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-row>
              <v-col cols="12" md="12">
                <device-settings :deviceInfo="editedItem" />
              </v-col>
              <v-col cols="12" md="12">
                <!-- {{ editedItem }} -->
                <device-state />
              </v-col>
              <!-- <v-col cols="12" md="8">
                  <div class="bg-red">Test 1</div>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <div class="bg-red">Test 2</div>
                </v-col>

                <v-col cols="12" md="8" sm="6">
                  <div class="bg-red">Test 3</div>
                </v-col> -->
              <v-col cols="12" md="12">
                <device-update :infos="editedItem"></device-update>
              </v-col>
              <v-col cols="12">
                <device-streams />
              </v-col>

              <v-col cols="12">
                <device-data-flow />
              </v-col>
            </v-row>

            <v-card-title class="text-h5">Transmitter</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <h2>P(x) = Ax^3 + Bx^2 + Cx + D</h2>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="A"
                    outlined
                    dense.multi-col-validation
                    placeholder="0"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="B"
                    outlined
                    dense.multi-col-validation
                    placeholder="0"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="C"
                    outlined
                    dense.multi-col-validation
                    placeholder="1"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="D"
                    outlined
                    dense.multi-col-validation
                    placeholder="0"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" outlined @click="dialog = false">
                <!-- Cancel -->
                Quiter
              </v-btn>
              <v-btn color="success" @click="updateInfo(editedItem)">
                <!-- Save -->
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon size="small" class="me-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon size="small" @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>

      <!-- Spinner de chargement du tableau -->
      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize"> Reset </v-btn> -->
        <v-progress-circular
          :size="70"
          :width="7"
          color="success"
        ></v-progress-circular>
      </template>
    </my-v-data-table>
  </v-container>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import DeviceState from "./deviceState.vue";
import DeviceDataFlow from "./deviceDataFlow.vue";
import DeviceStreams from "./deviceStreams.vue";
import DeviceUpdate from "./deviceUpdate.vue";
import deviceSettings from "./deviceSettings.vue";
import MyVDataTable from "../../components/myVDataTable/MyVDataTable";
export default {
  components: {
    DeviceState,
    DeviceDataFlow,
    DeviceStreams,
    DeviceUpdate,
    deviceSettings,
    MyVDataTable,
  },
  setup(props) {
    const loading = ref(false);
    const searchValue = ref("");
    const headers = ref([
      // { title: "ID", value: "_id" },
      { title: "MAC", key: "mac", visible: true },
      { title: "CONNECTION", key: "comType", visible: true },
      { title: "RSSI", key: "rssi", visible: true },
      { title: "ORG", key: "orgId.name", visible: true },
      //{ title: "CREATED", key: "createAt" },
      { title: "ACTIONS", key: "actions", visible: true },
    ]);

    let editedIndex = ref(-1);
    const formTitle = () => {
      return editedIndex.value === -1 ? "New Device" : "Edit Device";
    };

    const itemsSelected = ref([]);
    let dialog = ref(false);
    let dialogCreate = ref(false);
    let editedItem = ref();

    const load = (i) => {
      // loading[i].value = true;
      // setTimeout(() => (loading[i].value = false), 3000);
    };

    const editItem = (item) => {
      //console.log("Item" + item.name);
      editedItem.value = item;
      dialog.value = true;
    };

    const updateInfo = async (editedItem) => {
      dialog.value = false;
    };

    const store = useStore();
    const fetchData = async () => {
      await store.dispatch("deviceStore/getAllByOrg");
    };

    const items = computed(() => {
      return store.getters["deviceStore/getDevices"];
    });

    onMounted(async () => {
      await fetchData();
    });

    const dialogDelete = ref(false);
    let itemIdTobeDelelete = 0;

    const closeDelete = () => {
      dialogDelete.value = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem)
      //   this.editedIndex = -1
      // })
    };

    const deleteItemConfirm = async () => {
      closeDelete();
      console.log("Item " + itemIdTobeDelelete);
      itemIdTobeDelelete = 0;
    };

    const deleteItem = async (id) => {
      dialogDelete.value = true;
      console.log(id);
    };

    const close = () => {
      dialogCreate.value = false;
    };

    const save = () => {
      close();
    };

    return {
      loading,
      load,
      headers,
      searchValue,
      itemsSelected,
      items,
      editedItem,
      dialog,
      dialogDelete,
      dialogCreate,
      updateInfo,
      editItem,
      deleteItem,
      deleteItemConfirm,
      closeDelete,
      formTitle,
      close,
      save,
    };
  },
};
</script>

<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

import Axios from "@/_services/caller.service";
const urlOrgApi = "/api/v1/orgs/";

// initial state
const state = {
  all: [],
  organizations: [],
  organization: null,
};

// getters
const getters = {};

// actions
const actions = {
  async createOrganization(context, orgInfo) {
    //console.log(orgInfo);
    await Axios.post(urlOrgApi + "create", orgInfo)
      .then((res) => console.log(res))
      .catch((error) => console.log(error));
  },

  async updateOrganization(context, orgInfo) {
    //console.log(orgInfo);
    await Axios.put(urlOrgApi + "update", orgInfo)
      .then((res) => console.log(res))
      .catch((error) => console.log(error));
  },

  async deleteById(context, id) {
    await Axios.delete(urlOrgApi + id)
      .then((res) => {
        //console.log(res);
      })
      .catch((error) => console.log(error));
  },

  async updateById(context, org) {
    const { name, description } = org;
    console.log({ name, description });
    await Axios.patch(urlOrgApi + org._id, { name, description })
      .then((res) => {
        //console.log(res);
      })
      .catch((error) => console.log(error));
  },

  // Todo store organization id on redis
  async getById(context, id) {
    const currentOrg = localStorage.getItem("organization");
    await Axios.get(urlOrgApi + currentOrg)
      .then((res) => {
        //console.log("======>" + res.data);
        context.commit("setOrganization", res.data);
      })
      .catch((error) => console.log(error));
  },

  async getAllOrgs(context) {
    await Axios.get(urlOrgApi)
      .then((res) => {
        context.commit("setOrganizations", res.data);
      })
      .catch((error) => console.log(error));
  },
};

// mutations
const mutations = {
  setOrganizations(state, orgs) {
    state.organizations = orgs;
  },

  setOrganization(state, org) {
    state.organization = org;
  },

  // setProducts (state, products) {
  //   state.all = products
  // },
  // decrementProductInventory (state, { id }) {
  //   const product = state.all.find(product => product.id === id)
  //   product.inventory--
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <v-container fluid>
    <my-v-data-table
      :headers="headers2"
      :items="items2"
      :bname="thename"
      :filtrable="false"
      :searchable="false"
    ></my-v-data-table>
  </v-container>
</template>

<script>
import { computed, onBeforeMount, onMounted, ref } from "@vue/runtime-core";
import { includes } from "lodash";
import MyVDataTable from "../myVDataTable/MyVDataTable";
export default {
  components: {
    MyVDataTable,
  },
  setup() {
    const headers2 = ref([
      { title: "Nom", key: "name", visible: true },
      { title: "Âge", key: "age", visible: true },
      { title: "Pays", key: "country", visible: true },
    ]);

    const items2 = ref([
      { name: "JOHN DOE", age: 30, country: "USA" },
      { name: "JANE SMITH", age: 25, country: "Canada" },
      { name: "ALICE JOHNSON", age: 28, country: "UK" },
      { name: "BOB BROWN", age: 22, country: "Australia" },
    ]);
    const thename = ref("Demotable");
    let selected = [];
    let headers = ref([
      {
        title: "Dessert (100g serving)",
        align: "start",
        key: "name",
        sortable: false,
        removable: false,
        searchable: false,
        search: ref(""),
        filter: (value, search) => value.toString().includes(search),
      },
      {
        title: "Calories",
        key: "calories",
        removable: true,
        search: ref(""),
        filter: (value, search) => value.toString().includes(search),
      },
      {
        title: "Fat (g)",
        key: "fat",
        removable: true,
        search: ref(""),
        filter: (value, search) => value.toString().includes(search),
      },
      {
        title: "Carbs (g)",
        key: "carbs",
        removable: true,
        search: ref(""),
        filter: (value, search) => value.toString().includes(search),
      },
      {
        title: "Protein (g)",
        key: "protein",
        removable: true,
        search: ref(""),
      },
      { title: "Iron (%)", key: "iron", removable: true, search: ref("") },
    ]);
    let filters = ref({
      name: [],
      calories: [],
      fat: [],
      carbs: [],
      protein: [],
      iron: [],
    });

    const items = [
      {
        value: false,
        name: "Frozen Yogurt",
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0,
        iron: "1%",
      },
      {
        value: false,
        name: "Ice cream sandwich",
        calories: 237,
        fat: 9.0,
        carbs: 37,
        protein: 4.3,
        iron: "1%",
      },
      {
        value: false,
        name: "Eclair",
        calories: 262,
        fat: 16.0,
        carbs: 23,
        protein: 6.0,
        iron: "7%",
      },
      {
        value: false,
        name: "Cupcake",
        calories: 305,
        fat: 3.7,
        carbs: 67,
        protein: 4.3,
        iron: "8%",
      },
      {
        value: false,
        name: "Gingerbread",
        calories: 356,
        fat: 16.0,
        carbs: 49,
        protein: 3.9,
        iron: "16%",
      },
      {
        value: false,
        name: "Jelly bean",
        calories: 375,
        fat: 0.0,
        carbs: 94,
        protein: 0.0,
        iron: "0%",
      },
      {
        value: false,
        name: "Lollipop",
        calories: 392,
        fat: 0.2,
        carbs: 98,
        protein: 0,
        iron: "2%",
      },
      {
        value: false,
        name: "Honeycomb",
        calories: 408,
        fat: 3.2,
        carbs: 87,
        protein: 6.5,
        iron: "45%",
      },
      {
        value: false,
        name: "Donut",
        calories: 452,
        fat: 25.0,
        carbs: 51,
        protein: 4.9,
        iron: "22%",
      },
      {
        value: false,
        name: "KitKat",
        calories: 518,
        fat: 26.0,
        carbs: 65,
        protein: 7,
        iron: "6%",
      },
    ];

    const filteredDesserts = computed(() => {
      return items.filter((d) => {
        return Object.keys(filters.value).every((f) => {
          return filters.value[f].length < 1 || filters.value[f].includes(d[f]);
        });
      });
    });

    const columnValueList = (val) => {
      return items.map((d) => d[val]);
    };

    let headersSelected = ref([]);
    let currentHeaders = computed(() => {
      return headers.value.filter((item) =>
        headersSelected.value.includes(item.title)
      );
    });

    const remove = (key) => {
      headers.value = headers.value.filter((header) => header.key !== key);
    };

    // const search = ref(["Frozen", "Eclair"]);
    const search = ref("");
    const customFilter = (value, query, item) => {
      return (
        value != null &&
        query != null &&
        typeof value === "string" &&
        value.toString().toString().indexOf(query) !== -1
      );
    };

    let multiSearch = ref({});
    const filteredData = computed(() => {
      if (multiSearch.value) {
        return items.filter((item) => {
          return Object.entries(multiSearch.value).every(([key, value]) => {
            if (value.includes("|") && !value.includes("!")) {
              let el = value.split("|");
              return el.some((elem) =>
                (item[key] || "")
                  .toString()
                  .toUpperCase()
                  .startsWith(elem.toString().toUpperCase())
              );
            }
            if (value.substring(0, 1) === "!" && !value.includes("|")) {
              let el = value.split("!");
              return el.some(
                (elem) =>
                  !(item[key] || "")
                    .toString()
                    .toUpperCase()
                    .startsWith(elem.toString().toUpperCase())
              );
            }
            if (value.includes("|") && value.substring(0, 1) === "!") {
              let el = value.split("!")[1].split("|");
              return !el.some((elem) =>
                (item[key] || "")
                  .toString()
                  .toUpperCase()
                  .startsWith(elem.toString().toUpperCase())
              );
            }
            if (value.substring(0, 1) === ">") {
              let el = value.split(">");
              if (item[key] !== " ") {
                return Number(item[key] || "") > el[1];
              }
            }
            if (value.substring(0, 1) === "<") {
              let el = value.split("<");
              if (item[key] !== " ") {
                return Number(item[key] || "") < el[1];
              }
            }
            if (value.substring(0, 1) === "=") {
              let el = value.split("=");
              return (
                (item[key] || "").toString().toUpperCase() ===
                el[1].toString().toUpperCase()
              );
            }
            return (item[key] || "")
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase());
          });
        });
      } else {
        return items;
      }
    });

    const simpleFilter = computed((items, search) => {
      const searchv = search.toLowerCase();
      return items.filter((item) => {
        const text = item.title.toLowerCase();
        return text.indexOf(searchv) > -1;
      });
    });

    return {
      headers2,
      items2,
      filteredDesserts,
      items,
      headers,
      headersSelected,
      currentHeaders,
      filters,
      search,
      columnValueList,
      selected,
      remove,
      customFilter,
      filteredData,
      simpleFilter,
      multiSearch,
      thename,
    };
  },
};
</script>

<style></style>

import { createI18n } from "vue3-i18n";

const messages = {
  en: {
    menu: ["Home"],
    test: "test",
    devices: "Devices",
    probes: "Sensors",
    streams: "Streams",
    charts: "Charts",
    users: "Users",
    organizations: "Organizations",
    create: "Create",
    userslist: "Users",
    object: {
      a: "1233",
    },
    parse: "welcome to {name}",
    parses: {
      a: "welcome to {name}",
    },
  },
  fr: {
    menu: ["Lange francais"],
    test: "France",
    devices: "Noeuds",
    probes: "Capteurs",
    streams: "Flux",
    charts: "Courbes",
    users: "Utilisateurs",
    organizations: "Organisations",
    create: "Creer",
    userslist: "Lister",
    object: {
      a: "1233",
    },
    parse: "welcome to {name}",
    parses: {
      a: "welcome to {name}",
    },
  },
};

const i18n = createI18n({
  locale: "en",
  messages: messages,
});

export default i18n;

<template>
  <!-- <div>
    <v-row>
      <v-col cols="10"> </v-col>
      <v-col cols="1">
        <v-btn color="success mx-0 mt-2" @click="isGetAll = true">
          Create group</v-btn
        >
      </v-col>
      <v-col cols="1">
        <v-btn color="success mx-0 mt-2" @click="fetchSensorsGroup">
          All groups</v-btn
        >
      </v-col>
    </v-row>
  </div> -->
  <div class="d-flex flex-row-reverse mb-6">
    <v-btn
      class="ma-2 pa-2"
      color="success"
      variant="flat"
      @click="isGetAll = true"
    >
      Create
    </v-btn>
    <v-btn
      class="ma-2 pa-2"
      color="success"
      variant="flat"
      @click="fetchSensorsGroup"
    >
      &nbsp;&nbsp;&nbsp; List &nbsp;&nbsp;&nbsp;
    </v-btn>
  </div>

  <v-container fluid>
    <!-- <v-toolbar title="Notifications">
      <v-toolbar-items>
        <v-btn>Create</v-btn>
        <v-btn>List</v-btn>
      </v-toolbar-items>
      <v-divider vertical class="mx-2"></v-divider>
    </v-toolbar> -->
    <!-- <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success" variant="flat" @click="isGetAll = true">
        Create
      </v-btn>
      <v-divider vertical class="mx-1"></v-divider>
      <v-btn color="success" variant="flat" @click="fetchSensorsGroup">
        List
      </v-btn>
    </v-card-actions> -->
  </v-container>
  <div>
    <v-form class="multi-col-validation mx-4" v-if="isGetAll">
      <h3>Create sensors group</h3>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="formData.name"
            label="Group Name"
            variant="outlined"
            dense
            placeholder="Group Name"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-card class="overflow-y-auto">
            <v-banner class="justify-center text-h5 font-weight-light" sticky>
              Sensors can add
            </v-banner>
            <!-- <v-card-title> Sensors can add </v-card-title> -->
            <!-- <v-card-text v-for="(sensor, i) in sensorsList" :key="i">
                {{ sensor.id }}
              </v-card-text> -->
            <v-card-text>
              <v-text-field
                density="compact"
                variant="solo"
                label="Search templates"
                append-inner-icon="mdi-magnify"
                single-line
                hide-details
                @click:append-inner="onClick"
              ></v-text-field>
            </v-card-text>
            <v-card-text>
              <ul>
                <li v-for="(sensorId, i) in sensorsList" :key="i">
                  <span @click="addToList(sensorsList, sensorId)">
                    {{ sensorId }}</span
                  >
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="overflow-y-auto">
            <v-banner class="justify-left text-h5 font-weight-light" sticky>
              Sensors in group
            </v-banner>
            <v-card-text>
              <v-text-field
                density="compact"
                variant="solo"
                label="Search templates"
                append-inner-icon="mdi-magnify"
                single-line
                hide-details
                @click:append-inner="onClick"
              ></v-text-field>
            </v-card-text>
            <v-card-text>
              <ul>
                <li v-for="(sensorId, i) in sensorsListToBeCreated" :key="i">
                  <span
                    @click="removeFromList(sensorsListToBeCreated, sensorId)"
                  >
                    {{ sensorId }}
                  </span>
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn color="success" @click="submit"> Submit </v-btn>
          <v-btn color="error" type="reset" outlined class="mx-2">
            Reset
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>

  <v-container fluid>
    <!-- {{ items }} -->
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      :loading="loading"
      loading-text="Loading... Please wait"
      item-value="name"
      show-select
      show-expand
      class="elevation-1"
    >
      <!-- <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Expandable Table</v-toolbar-title>
        </v-toolbar>
      </template> -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Sensors group</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog">
            <template v-slot:activator="{ props }">
              <v-btn dark class="mb-2" v-bind="props"> New Item </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle() }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.name"
                        label="Name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.sensorsIDs"
                        label="Sensors"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-card class="overflow-y-auto">
                            <v-banner
                              class="justify-center text-h5 font-weight-light"
                              sticky
                            >
                              Sensors can add
                            </v-banner>
                            <v-card-text>
                              <v-text-field
                                density="compact"
                                variant="solo"
                                label="Search templates"
                                append-inner-icon="mdi-magnify"
                                single-line
                                hide-details
                                @click:append-inner="onClick"
                              ></v-text-field>
                            </v-card-text>
                            <v-card-text>
                              <ul>
                                <li
                                  v-for="(sensorId, i) in sensorsList"
                                  :key="i"
                                >
                                  <span
                                    @click="addToList(sensorsList, sensorId)"
                                  >
                                    {{ sensorId }}</span
                                  >
                                </li>
                              </ul>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-card class="overflow-y-auto">
                            <v-banner
                              class="justify-left text-h5 font-weight-light"
                              sticky
                            >
                              Sensors in group
                            </v-banner>
                            <v-card-text>
                              <v-text-field
                                density="compact"
                                variant="solo"
                                label="Search templates"
                                append-inner-icon="mdi-magnify"
                                single-line
                                hide-details
                                @click:append-inner="onClick"
                              ></v-text-field>
                            </v-card-text>
                            <v-card-text>
                              <ul>
                                <li
                                  v-for="(
                                    sensorId, i
                                  ) in sensorsListToBeCreated"
                                  :key="i"
                                >
                                  <span
                                    @click="
                                      removeFromList(
                                        sensorsListToBeCreated,
                                        sensorId
                                      )
                                    "
                                  >
                                    {{ sensorId }}
                                  </span>
                                </li>
                              </ul>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" variant="text" @click="close">
                  Cancel
                </v-btn>
                <v-btn color="green" variant="text" @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Dialog de suppression d'une ligne du tableau -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this group?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <!-- Affiche les capteur du group -->
      <template v-slot:expanded-row="{ columns, item }">
        <tr>
          <td :colspan="columns.length">
            Sensors on group {{ item.raw.name }}
          </td>
        </tr>
        <tr>
          <td :colspan="columns.length">
            <span v-for="(item, index) in item.raw.sensorsIDs" :key="index">
              {{ item }} &nbsp;
            </span>
          </td>
        </tr>
      </template>

      <!-- Les bouton d'action sur chaque ligne du tableau -->
      <template v-slot:item.actions="{ item }">
        <v-icon size="small" class="me-2" @click="editItem(item.raw)">
          mdi-pencil
        </v-icon>
        <v-icon size="small" @click="deleteItem(item.raw)"> mdi-delete </v-icon>
      </template>

      <!-- Spinner de chargement du tableau -->
      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize"> Reset </v-btn> -->
        <v-progress-circular
          :size="70"
          :width="7"
          color="success"
          indeterminate
        ></v-progress-circular>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { onBeforeMount, computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import Axios from "@/_services/caller.service";

export default {
  setup() {
    let organizationName = ref("");
    let formData = ref({
      name: "",
      orgID: localStorage.getItem("organization"),
      sensorsIDs: [],
    });
    const city = ref("");
    const country = ref("");
    const company = ref("");
    const email = ref("");
    const checkbox = ref(false);
    let loading = ref(true);

    const store = useStore();

    const submit = () => {
      formData.value.sensorsIDs = sensorsListToBeCreated.value;
      store.dispatch("sensorsStore/createSensorsGroup", formData.value);
      //isGetAll.value = false;
      fetchSensorsGroup();
    };

    const onClick = () => {};

    let isGetAll = ref(false);
    const getAll = () => {
      isGetAll.value = false;
      store.dispatch("sensorsStore/getAllSensorsGroup");
    };

    let sensorsListToBeCreated = ref([]);
    const addToList = (arr, sensorId) => {
      console.log(sensorsList);
      var index = arr.indexOf(sensorId);
      //console.log(index);
      if (index > -1) {
        sensorsListToBeCreated.value.push(sensorId);
        //sensorsListToBeCreated.value = sensorsListToBeCreated.value.filter(onlyUnique);
        sensorsListToBeCreated.value = [
          ...new Set(sensorsListToBeCreated.value.filter(onlyUnique)), //https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
        ];
        arr.splice(index, 1);
        //arr.splice(index, 1);
      }
    };

    const removeFromList = (arr, sensorId) => {
      var index = arr.indexOf(sensorId);
      if (index > -1) {
        arr.splice(index, 1);
      }
    };

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    let sensorsList = [];
    sensorsList = computed(() => {
      let data = [];
      store.getters.getSensorsInfo.forEach((element) => {
        data.push(element._id);
      });
      return data;
    });

    const headers = [
      { title: "NAME", key: "name" },
      { title: "ORG", key: "orgID" },
      { title: "SENSORS", key: "sensorsIDs" },
      { title: "CREATED", key: "createdAt" },
      { title: "UPDATE", key: "updatedAt" },
      { title: "ACTION", key: "actions" },
    ];

    let items = ref([]);

    // Sensors group list
    let tableData = ref([]); //= computed(() => store.state.sensorsStore.sensorsGroup);
    const tableDataListe = computed(
      () => store.state.sensorsStore.sensorsGroup
    );
    const formatDate = (timestamp) => {
      var day = moment(timestamp);
      return day;
    };

    let dialog = ref(false);
    //let editedIndex = ref([]);

    ////////////////
    let editedIndex = ref(-1);
    const formTitle = () => {
      return editedIndex.value === -1 ? "New Item" : "Edit Item";
    };

    let editedItem = ref({
      name: "organ ",
      description: "Descrip",
    });

    const editItem = (item) => {
      console.log("Item" + item.name);
      // editedItem.value = item;
      // dialog.value = true;
      // = this.organizations.indexOf(item);
      editedItem.value = Object.assign({}, item);
      dialog.value = true;
    };

    const close = () => {
      dialog.value = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem)
      //   this.editedIndex = -1
      // })
    };

    const save = () => {
      // updateItem(editedItem.value);
      // fetchOrgs();
      close();
    };

    const dialogDelete = ref(false);
    const closeDelete = () => {
      dialogDelete.value = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem)
      //   this.editedIndex = -1
      // })
    };

    ////////////////////////

    const update = (item) => {
      //console.log(editedIndex.value.map((i) => i.textContent));
      console.log("Index " + item.name);
      store.dispatch("sensorsStore/updateSensorsGroup", item);
    };

    onBeforeMount(() => {
      fetchSensorsGroup();
    });

    const fetchSensorsGroup = async () => {
      isGetAll.value = false;
      // await Axios.get(url + "/api/users", {
      await Axios.get("/api/v1/sensorsgroup")
        .then((res) => {
          tableData.value = res.data;
          items.value = res.data;
          loading.value = false;
        })
        .catch((error) => console.log(error));
    };

    let itemIdTobeDelelete = 0;
    const deleteItem = async (item) => {
      console.log(item);
      dialogDelete.value = true;
      itemIdTobeDelelete = item._id;
    };

    const deleteItemConfirm = async () => {
      closeDelete();
      console.log("Item " + itemIdTobeDelelete);
      await Axios.delete("/api/v1/sensorsgroup/" + itemIdTobeDelelete)
        .then((res) => {
          fetchSensorsGroup();
        })
        .catch((error) => console.log(error));
      itemIdTobeDelelete = 0;
    };

    return {
      organizationName,
      city,
      country,
      company,
      email,
      checkbox,
      isGetAll,

      tableData,
      formData,
      formatDate,
      dialog,
      editItem,
      update,
      deleteItem,
      fetchSensorsGroup,

      submit,
      getAll,
      removeFromList,
      addToList,
      onClick,
      headers,
      items,
      formTitle,
      editedItem,
      closeDelete,
      deleteItemConfirm,
      close,
      save,
      loading,
      dialogDelete,

      sensorsList,
      sensorsListToBeCreated,
    };
  },
};
</script>

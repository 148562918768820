<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      :loading="loading"
      loading-text="Loading... Please wait"
      item-value="name"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar color="green-lighten-5">
          <v-toolbar-title>{{ $t("streams") }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ props }">
              <!-- <v-btn color="primary" dark class="mb-2" v-bind="props">
              New Item
            </v-btn> -->
            </template>

            <v-card class="overflow-y-auto" max-width="700px">
              <!-- <v-card-title>
              <span class="text-h5">{{ formTitle() }}</span>
            </v-card-title> -->

              <v-card-title class="text-h5">Polynomial fit</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <h2>P(x) = Ax^3 + Bx^2 + Cx + D</h2>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.coefficients[0]"
                      label="A"
                      outlined
                      dense.multi-col-validation
                      placeholder="0"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.coefficients[1]"
                      label="B"
                      outlined
                      dense.multi-col-validation
                      placeholder="0"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.coefficients[2]"
                      label="C"
                      outlined
                      dense.multi-col-validation
                      placeholder="1"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.coefficients[3]"
                      label="D"
                      outlined
                      dense.multi-col-validation
                      placeholder="0"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" variant="text" @click="close">
                  Cancel
                </v-btn>
                <v-btn color="green" variant="text" @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Delete user -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this users?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Dialog de suppression d'une ligne du tableau -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5 overflow-y-auto"
                >Are you sure you want to delete this stream?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <!-- Les bouton d'action sur chaque ligne du tableau -->
      <template v-slot:item.actions="{ item }">
        <v-icon size="small" class="me-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon size="small" @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>

      <!-- Spinner de chargement du tableau -->
      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize"> Reset </v-btn> -->
        <v-progress-circular
          :size="70"
          :width="7"
          color="success"
          indeterminate
        ></v-progress-circular>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import Axios from "@/_services/caller.service";

export default {
  setup() {
    const title = "Users Group List";
    const headers = [
      //{ title: "ID", key: "_id" },
      { title: "ID", key: "streamId" },
      { title: "STREAM", key: "value" },
      { title: "SENSOR", key: "sensorValue" },
      { title: "TYPE", key: "sensorType" },
      { title: "COEF", key: "coefficients" },
      { title: "DATE", key: "timestamp" },
      { title: "ACTIONS", key: "actions" },
    ];

    const dialog = ref(false);
    const dialogDelete = ref(false);
    const loading = ref(false);
    let items = ref([]);

    let editedIndex = ref(-1);
    const formTitle = () => {
      return editedIndex.value === -1 ? "New Item" : "Edit Item";
    };

    let editedItem = ref({
      name: "organ ",
      description: "Descrip",
      coefficients: [],
    });

    let itemIdTobeDelelete = 0;
    const editItem = (item) => {
      console.log("Item" + item.name);
      editedItem.value = Object.assign({}, item);
      dialog.value = true;
    };

    const deleteItemConfirm = async () => {
      closeDelete();
      console.log("Item " + itemIdTobeDelelete);
      itemIdTobeDelelete = 0;
    };

    const deleteItem = async (id) => {
      dialogDelete.value = true;
    };

    const close = () => {
      dialog.value = false;
    };

    const save = () => {
      console.log(editedItem.value);
      updateStreamInfo(editedItem.value);
      close();
    };

    const closeDelete = () => {
      dialogDelete.value = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem)
      //   this.editedIndex = -1
      // })
    };

    let datasList = ref([]);
    onMounted(() => {
      getAll();
    });

    ////
    const store = useStore();

    const getDateTime = (timestamp) => {
      return new Date(timestamp * 1000).toLocaleString(); //toString();
    };

    // const getAll = async () => {
    //   const org = localStorage.getItem("organization");
    //   let data = [];
    //   await Axios.get("/api/v1/streams/orgs/" + org)
    //     .then((res) => {
    //       items.value = res.data;

    //       items.value.forEach((element) => {
    //         element.timestamp = getDateTime(element.timestamp);
    //         element.sensorValue =
    //           element.coefficients[0] *
    //             element.value *
    //             element.value *
    //             element.value +
    //           element.coefficients[1] * element.value * element.value +
    //           element.coefficients[2] * element.value +
    //           element.coefficients[3];
    //       });
    //     })
    //     .catch((error) => console.log(error));
    // };

    const getAll = async () => {
      let data = [];
      await Axios.get("/api/v1/streams")
        .then((res) => {
          //console.log(res);
          items.value = res.data;

          items.value.forEach((element) => {
            element.timestamp = getDateTime(element.timestamp);
            element.sensorValue =
              element.coefficients[0] *
                element.value *
                element.value *
                element.value +
              element.coefficients[1] * element.value * element.value +
              element.coefficients[2] * element.value +
              element.coefficients[3];
          });
        })
        .catch((error) => console.log(error));
    };

    const updateStreamInfo = async (editedItem) => {
      dialog.value = false;
      await Axios.put("/api/v1/streams/" + editedItem._id, {
        //id: editedItem.sensorId,
        coefficients: editedItem.coefficients,
      })
        .then((res) => {
          //console.log(res);
        })
        .catch((error) => console.log(error));
    };

    return {
      title,
      headers,
      items,
      dialogDelete,
      dialog,
      formTitle,
      save,
      close,
      closeDelete,
      editItem,
      deleteItem,
      deleteItemConfirm,

      updateStreamInfo,

      datasList,
      editedItem,
      loading,
    };
  },
};
</script>

<style></style>

//const urlSensorApi = "http://localhost:9100/api/v1/alerts/";
const urlSensorApi = process.env.VUE_APP_URL + "api/ui/alerts/v1";
import Axios from "@/_services/caller.service";

const url = {
  notification: "/api/v1/notificationlist",
};

// initial state
const state = {
  all: [],
  alerts: [],
  sensorsGroup: [],
  sensors: [],
  usersGroup: [],
  allNotificationList: [],
};

// getters
const getters = {
  getUsersGroups(state, getters) {
    return state.usersGroup;
  },

  getNotificationList(state, getters) {
    return state.allNotificationList;
  },

  getSensors(state, getters) {
    return state.sensors;
  },
};

// actions
const actions = {
  async createAlert(context, alertData) {
    //console.log(alertData);
    await Axios.post("/api/v1/alertCondition", alertData)
      .then((res) => console.log(res))
      .catch((error) => console.log(error));
  },

  async updateAlert(context, alertData) {
    //console.log(alertData);
    await Axios.put("/api/v1/alertCondition", alertData)
      .then((res) => console.log(res))
      .catch((error) => console.log(error));
  },

  //
  async getAllAlerts(context) {
    await Axios.get("/api/v1/alertCondition")
      .then((res) => {
        //console.log(res);
        context.commit("setAlerts", res.data);
      })
      .catch((error) => console.log(error));
  },

  //************** Sensors group
  async createSensorsGroup(context, data) {
    //console.log(data);
    await Axios.post("/api/v1/sensorsgroup", data)
      .then((res) => {
        //console.log(res);
      })
      .catch((error) => console.log(error));
  },

  async getAllSensorsGroup(context) {
    //data = JSON.parse(JSON.stringify(alertData));
    await Axios.get("/api/v1/sensorsgroup")
      .then((res) => {
        //console.log(res);
        context.commit("setSensorsGroup", res.data);
      })
      .catch((error) => console.log(error));
  },

  // async getAllSensorsByOrg(context, org) {
  //   await Axios.get("/api/v1/sensors/" + org)
  //     .then((res) => {
  //       console.log(res);
  //       context.commit("setSensors", res.data);
  //     })
  //     .catch((error) => console.log(error));
  // },

  async getAllSensors(context) {
    await Axios.get("/api/v1/sensors")
      .then((res) => {
        context.commit("setSensors", res.data);
      })
      .catch((error) => console.log(error));
  },

  //*************** Users group
  async createUsersGroup(context, data) {
    //console.log(data);
    await Axios.post("/api/v1/usersgroup", data)
      .then((res) => console.log(res))
      .catch((error) => console.log(error));
  },

  async deleteUsersGroup(context, id) {
    await Axios.delete("/api/v1/usersgroup/" + id)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  },

  async getAllUsersGroup(context) {
    //data = JSON.parse(JSON.stringify(alertData));
    await Axios.get("/api/v1/usersgroup")
      .then((res) => {
        //console.log(res.data);
        // Attention les mot de pass sont la
        context.commit("setUsersGroups", res.data);
      })
      .catch((error) => console.log(error));
  },

  async setSingleSensorAlert(context, payload) {
    await Axios.put("/api/v1/singlealert/" + payload.id, payload)
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => console.log(error));
  },

  async updateSensorsInfo(context, payload) {
    console.log("CALL UPDATE SENSOR");
    // await Axios.put("/api/v1/singlealert/" + payload.id, payload)
    //   .then((res) => {
    //     // console.log(res);
    //   })
    //   .catch((error) => console.log(error));
  },

  async setAlertAck(context, payload) {
    await Axios.post("/api/v1/singlealert/alertack", payload)
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => console.log(error));
  },

  // Notification list
  async createNotificationList(context, payload) {
    await Axios.post(url.notification, payload)
      .then((res) => {
        c; //onsole.log(res);
        console.log("Success created");
      })
      .catch((error) => console.log(error));
  },

  async getAllNotificationList(context) {
    await Axios.get(url.notification)
      .then((res) => {
        //console.log(res.data);
        context.commit("setNotificationList", res.data);
      })
      .catch((error) => console.log(error));
  },

  async deleteNotificationList(context, id) {
    await Axios.delete("/api/v1/notificationlist/" + id)
      .then((res) => {
        console.log("Delete success");
      })
      .catch((err) => console.log(err));
  },
};

// mutations
const mutations = {
  setAlerts(state, alerts) {
    state.alerts = alerts;
  },

  setSensorsGroup(state, group) {
    state.sensorsGroup = group;
  },

  setSensors(state, sensors) {
    state.sensors = sensors;
  },

  setUsersGroups(state, group) {
    state.usersGroup = group;
  },

  setNotificationList(state, group) {
    state.allNotificationList = group;
  },

  // setProducts (state, products) {
  //   state.all = products
  // },
  // decrementProductInventory (state, { id }) {
  //   const product = state.all.find(product => product.id === id)
  //   product.inventory--
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

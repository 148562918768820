<template>
  <!-- <div class="d-flex flex-row-reverse mb-6">
    <v-dialog v-model="dialogAddOrg" persistent>
      <template v-slot:activator="{ props }">
        <v-btn class="ma-2 pa-2" color="success" variant="flat" v-bind="props">
          Create
        </v-btn>
      </template>
      <v-card max-width="700px">
        <v-card-title>
          <span class="text-h5">Create Org</span>
        </v-card-title>
        <v-card-text> 
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="formData.name"
                label="Name"
                outlined
                dense
                placeholder="Name"
                hide-details
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="momentTzList"
                density="comfortable"
                label="Contry"
                v-model="formData.tz"
                return-object
                single-line
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formData.address"
                label="Address"
                outlined
                dense
                placeholder="Address"
                hide-details
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="formData.description"
                filled
                auto-grow
                label="Description"
                rows="4"
                row-height="30"
                shaped
                required
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-btn color="primary" @click="submit"> Submit </v-btn>
              <v-btn type="reset" outlined class="mx-2"> Reset </v-btn>
            </v-col>
            <v-alert type="success" variant="outlined">
              Delete Succes!
            </v-alert>
          </v-row>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="dialogAddOrg = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn class="ma-2 pa-2" color="success" variant="flat" @click="fetchOrgs">
      &nbsp;&nbsp;&nbsp; List &nbsp;&nbsp;&nbsp;
    </v-btn>
  </div>
  <h3>{{ $t("organizations") }}</h3> -->
  <!-- {{ organizations }} -->
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="organizations"
      :items-per-page="10"
      :loading="loading"
      loading-text="Loading... Please wait"
      item-value="name"
      show-select
      show-expand
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="green-lighten-5">
          <v-toolbar-title>Organizations</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ props }">
              <v-btn dark class="mb-2" v-bind="props">Create</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle() }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.name"
                        label="Name"
                        variant="outlined"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :items="momentTzList"
                        density="comfortable"
                        label="Tmiezone"
                        v-model="editedItem.tz"
                        variant="outlined"
                        return-object
                        single-line
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <!-- <v-text-field
                      v-model="editedItem.address"
                      label="Address"
                      variant="outlined"
                      dense
                      placeholder="Address"
                      hide-details
                      required
                    ></v-text-field> -->
                      <v-textarea
                        v-model="editedItem.address"
                        label="Address"
                        filled
                        auto-grow
                        rows="4"
                        row-height="30"
                        shaped
                        required
                        variant="outlined"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <!-- <v-text-field
                      v-model="editedItem.description"
                      variant="outlined"
                      label="Description"
                    ></v-text-field> -->

                      <v-textarea
                        v-model="editedItem.description"
                        filled
                        auto-grow
                        label="Description"
                        rows="4"
                        row-height="30"
                        shaped
                        required
                        variant="outlined"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" variant="text" @click="close">
                  Cancel
                </v-btn>
                <v-btn color="green" variant="text" @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon size="small" class="me-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon size="small" @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize"> Reset </v-btn> -->
        <v-progress-circular
          :size="70"
          :width="7"
          color="success"
          indeterminate
        ></v-progress-circular>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted, ref } from "@vue/runtime-core";
import momentTzList from "./validMomentTz.ts";
export default {
  setup(props) {
    const store = useStore();
    const loading = ref(true);

    let formData = ref({
      name: "",
      address: "",
      tz: "",
      description: "",
    });

    const submit = async () => {
      //form.value.resetValidation();
      await store.dispatch(
        "organizationStore/createOrganization",
        formData.value
      );
    };

    // Gestion de la liste des organisations
    let dialogAddOrg = ref(false);

    const fetchOrgs = async () => {
      loading.value = true;
      await store.dispatch("organizationStore/getAllOrgs");
      organizations.value = store.state.organizationStore.organizations;
      loading.value = false;
    };

    onMounted(() => {
      fetchOrgs();
    });

    let organizations = ref([]);
    // organizations = computed(() => {
    //   return store.state.organizationStore.organizations;
    // });

    let dialog = ref(false);
    let editedItem = ref({
      name: "Organisaion name",
      address: "Organisaion address",
      tz: "Organisaion tz",
      description: "Description",
    });

    const editItem = (item) => {
      //console.log(item);
      isEditedItem.value = true;
      editedItem.value = Object.assign({}, item);
      dialog.value = true;
    };

    const deleteItem = async (item) => {
      //console.log(item);
      dialogDelete.value = true;
      isEditedItem.value = false;
      editedItem.value = Object.assign({}, item);
    };

    const deleteItemConfirm = async () => {
      closeDelete();
      console.log("Item " + editedItem.value._id);
      await store.dispatch(
        "organizationStore/deleteById",
        editedItem.value._id
      );
      fetchOrgs();
    };

    const updateItem = async (item) => {
      dialog.value = false;
      console.log(item._id);
      await store.dispatch("organizationStore/updateById", item);
    };

    const initialize = () => {};

    let isEditedItem = ref(false);
    const formTitle = () => {
      return isEditedItem.value === true ? "Edit Org" : "Create Org";
    };

    const close = () => {
      dialog.value = false;
      isEditedItem.value = false;
    };

    const save = () => {
      updateItem(editedItem.value);
      fetchOrgs();
      close();
    };

    const closeDelete = () => {
      dialogDelete.value = false;
    };

    const dialogDelete = ref(false);

    const headers_old = [
      // { text: "ID", sortable: false, value: "id" },
      { text: "NAME", value: "full_name" },
      { text: "DESCRIPTION", value: "description" },
      { text: "ACTIONS", value: "action" },
    ];
    const headers = [
      //{ title: "ID", sortable: false, key: "_id" },
      { title: "NAME", key: "name" },
      { title: "DESCRIPTION", key: "description" },
      { title: "CREATED AT", key: "createdAt" },
      { title: "ACTIONS", key: "actions" },
    ];

    return {
      fetchOrgs,
      updateItem,
      deleteItem,
      deleteItemConfirm,
      editItem,
      initialize,
      headers,
      organizations,
      dialogAddOrg,
      dialog,
      editedItem,
      formTitle,
      dialogDelete,
      closeDelete,
      close,
      save,
      formData,
      submit,
      loading,
      momentTzList,
    };
  },
};
</script>

<style></style>

<template>
  <v-card class="flex" dark flat tile color="green">
    <v-card-text class="py-2 white--text text-center"
      >@{{ new Date().getFullYear() }} — <strong>htech-{{ gitHash }}</strong>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Footer_Name",
  setup() {
    const msg = "FOOTER";
    const icons = [
      "mdi-facebook",
      "mdi-twitter",
      "mdi-linkedin",
      "mdi-instagram",
    ];

    const gitHash = process.env.VUE_APP_GIT_HASH || "N/A";

    return { msg, icons, gitHash };
  },
};
</script>

<style>
.footer {
  background-color: rgb(123, 204, 191);
}
</style>

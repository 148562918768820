// Video important : https://www.youtube.com/watch?v=m73vd9hqZj0
//import dotenv from "dotenv";
import Axios from "../_services/caller.service";
import router from "@/router";
import axios from "axios";
const urlBase = process.env.VUE_APP_URL;

export const signup = async (
  context,
  { email, password, organization, isAdmin }
) => {
  await axios
    .post(url + "auth/signup", {
      email: email,
      password: password,
      organization: organization,
      isAdmin: isAdmin,
    })
    .then((res) => {
      //console.log(res);
      const token = res.data.token;
      console.log(token);
      context.commit("setToken", token);
      localStorage.setItem("jwt", token);
      axios.defaults.headers.common["Authorization"] = token;
    })
    .catch((error) => console.log(error));
};

// User info actiom
export const getUserInfo = async (context, email) => {
  await axios
    .get(urlUserApi + "user/org/" + email, {
      headers: {
        //Authorization: "Bearer " + access_token,
      },
    })
    .then((res) => {
      console.log(res.data);
      // localStorage.setItem("login", true);
      // localStorage.setItem("organization", res.data[0].name);
      // localStorage.setItem("user", email);
      const userData = {
        userId: "1",
        //token: access_token,
        userOrg: res.data,
        userEmail: email,
      };
      context.commit("setUserId", "6788");
      context.commit("setUser", userData);
    })
    .catch((error) => console.log("RETOUR" + error));
};

/// Get sensors by microservice
export const getSensorList = async (context) => {
  const axiosInstance = axios.create({
    baseURL: urlBase,
    timeout: 1000,
    headers: { Authorization: "Bearer " },
  });

  let sensorsInfo = [];
  await axiosInstance
    .get("/sensors/list")
    .then((res) => {
      // console.log("===============>" + res.data);
      res.data.forEach((element) => {
        //console.log(element.organizations);
        //console.log(element.id);
        //laet orga = element.organizations;
        element.organizations.forEach((el) => {
          //console.log(el);
          if (el === localStorage.getItem("organization")) {
            //element.timestamp = "123";
            element.mongoId = element.id;
            element.name = element.sensorId;
            element.macId = element.id;
            sensorsInfo.push(element);
          }
        });
      });
      context.commit("setSensorsInfo", sensorsInfo);
      context.commit("setSensors", res.data);
    })
    .catch((err) => console.log(err));
};

/// Get sensors by microservice
export const getSensorsForUserOrg = async (context, org) => {
  let sensorsInfo = [];
  await Axios.get("/api/v1/sensors/orgs/user")
    .then((res) => {
      //console.log(res.data);
      res.data.forEach((element) => {
        sensorsInfo.push(element);
      });
      context.commit("setSensorsInfo", sensorsInfo);
      context.commit("setSensors", res.data);
    })
    .catch((err) => console.log(err));
};

export const fetchVersion = async (context, org) => {
  await Axios.get("/api/v1/software/version")
    .then((res) => {
      //console.log(res.data.version);
      context.commit("setSoftVersion", res.data);
    })
    .catch((err) => console.log(err));
};

export const getStreamsByOrg = async (context, org) => {
  const axiosInstance = axios.create({
    baseURL: urlBase,
    withCredentials: true,
    timeout: 2000,
  });

  const access_token = localStorage.getItem("jwt");
  let streamsInfo = [];
  await axiosInstance
    .get("/api/v1/streams/orgs/" + org, {
      // headers: {
      //   Authorization: `Bearer ${access_token}`,
      // },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-Requested-With": "Axios",
        withCredentials: true,
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((res) => {
      //console.log(res.data);
      res.data.forEach((element) => {
        streamsInfo.push(element);
      });
      context.commit("setStreamsInfo", streamsInfo);
      context.commit("setStreams", res.data);
    })
    .catch((err) => console.log(err));
};

/// Sensors action
export const getAllSensors = async (context) => {
  const access_token = localStorage.getItem("jwt");
  const axiosInstance = axios.create({
    baseURL: url,
    timeout: 1000,
    headers: { Authorization: `Bearer ${access_token}` },
  });

  let sensorsInfo = [];
  await axiosInstance
    .get("/iotnode")
    .then((res) => {
      //console.log(res.data);
      res.data.forEach((element) => {
        //console.log(element);
        const sensorsArray = element.measurements[0].sensors;
        //console.log(element.measurements[0].timestamp);
        //console.log(sensorsArray);
        const timestamp = element.measurements[0].timestamp;
        const id = element._id;
        const macId = element.iotnode_id;
        sensorsArray.forEach((element) => {
          // element.low = 20;
          // element.high = 30;
          // element.alarm = 70;
          element.timestamp = timestamp;
          element.mongoId = id;
          element.macId = macId.toLowerCase() + "_" + element.id;
          sensorsInfo.push(element);
          //console.log(element);
        });
      });
      context.commit("setSensorsInfo", sensorsInfo);
      context.commit("setSensors", res.data);
    })
    .catch((err) => console.log(err));
};

export const getSensorById = async (context, id) => {
  await axios
    .get(url + "iotnode/" + id)
    .then((res) => {
      //console.log(res);
    })
    .catch((error) => console.log(error));
};

export const setSensorAlertById = async (
  context,
  { id, channel, low, high, alarm }
) => {
  await axios
    .put(url + "iotnode/" + id, {
      channel: channel,
      low: low,
      high: high,
      alarm: alarm,
      description: "New description",
    })
    .then((res) => {
      //console.log(res);
    })
    .catch((error) => console.log(error));
  console.log("Alert put action", low, high, alarm);
  console.log("_id : ", id);
};

export const getHistoryById = async () => {};

export const getSensorsByGroup = async () => {
  console.log("Action Get sensor by goup");
};

export const fetchSensorHistory = async (context, sensorId) => {
  console.log("Action : Fetch sensor history");
  const axiosInstance = axios.create({
    baseURL: urlBase,
    timeout: 1000,
    //headers: { Authorization: "Bearer " },
  });

  //let sensorsInfo = [];
  axiosInstance
    .get("/sensors/" + sensorId)
    .then((res) => {
      console.log(res.data);
      res.data.forEach((element) => {
        element.organizations.forEach((el) => {
          console.log(el);
        });
      });
      // context.commit("setSensorsInfo", sensorsInfo);
      // context.commit("setSensors", res.data);
    })
    .catch((err) => console.log(err));
};

export const toogleDrawer = (context) => {
  context.commit("toggleDrawer");
};

export const getAllUsers = () => {};

// Axios.interceptors.response.use(
//   (response) => {
//     console.log("========= Intercept call");
//     return response;
//   },
//   (error) => {
//     console.log("========= Intercept error");
//     console.log(error);
//     if (!error.response) {
//       return Promise.reject(error);
//     } else {
//       if (error.response.status == 401) {
//         this.logout();
//         router.push("/login");
//       } else {
//         // Erreur de l'API
//         return Promise.reject(error);
//       }
//     }
//   }
// );

//export default Axios;

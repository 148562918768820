<template>
  <!-- Sélecteur des colonnes à afficher -->
  <!-- <v-sheet border rounded> -->
  <v-row v-if="searchable">
    <v-col cols="auto" class="d-flex justify-end">
      <v-select
        v-model="selectedColumns"
        :items="allColumns"
        item-text="title"
        item-value="key"
        label="Selections"
        multiple
        dense
        @change="filterItems"
        style="width: 300px"
      />
    </v-col>
  </v-row>

  <!-- Champs de recherche pour chaque colonne visible -->
  <v-row v-if="filtrable">
    <v-col v-for="header in visibleHeaders" :key="header.key" cols="4">
      <v-text-field
        v-model="filters[header.key]"
        :label="`Recherche par ${header.title}`"
        clearable
        @input="filterItems"
        dense
      ></v-text-field>
    </v-col>
  </v-row>

  <!-- Tableau de données avec colonnes visibles et filtres -->
  <!-- <v-container fluid> -->
  <v-data-table
    :headers="visibleHeaders"
    :items="filteredItems"
    item-key="id"
    loading-text="Loading... Please wait"
    item-value="name"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar color="green-lighten-5">
        <v-toolbar-title>{{ bname }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
  </v-data-table>
  <!-- </v-container> -->
  <!-- </v-sheet> -->
</template>

<script>
import { defineComponent, ref, watch, computed } from "vue";

export default defineComponent({
  name: "MyVDataTable",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    bname: {
      type: String,
      required: true,
    },
    searchable: {
      type: Boolean,
      required: true,
    },
    filtrable: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const filters = ref({});
    const filteredItems = ref([...props.items]);

    // Colonnes disponibles pour le v-select
    const allColumns = computed(() => {
      return props.headers.map((header) => ({
        title: header.title,
        key: header.key,
      }));
    });

    // Colonnes visibles (sélectionnées par l'utilisateur)
    const selectedColumns = ref(props.headers.map((header) => header.key));

    // Colonnes visibles filtrées en fonction de la sélection
    const visibleHeaders = computed(() => {
      return props.headers.filter((header) =>
        selectedColumns.value.includes(header.key)
      );
    });

    // Fonction de filtrage des éléments selon les critères de recherche
    const filterItems = () => {
      filteredItems.value = props.items.filter((item) => {
        return Object.keys(filters.value).every((key) => {
          const filterValue = filters.value[key];
          if (!filterValue) return true;
          if (typeof item[key] === "string") {
            return item[key].toUpperCase().includes(filterValue.toUpperCase());
          }
          return item[key].toString().includes(filterValue);
        });
      });
    };

    // Refiltrer dès que les items ou les colonnes visibles changent
    watch(
      () => props.items,
      () => {
        filterItems();
      },
      { immediate: true }
    );

    // Refiltrer dès que les colonnes sélectionnées changent
    watch(
      () => selectedColumns.value,
      () => {
        filterItems();
      },
      { immediate: true }
    );

    return {
      filters,
      filteredItems,
      visibleHeaders,
      filterItems,
      selectedColumns,
      allColumns,
    };
  },
});
</script>

<style scoped>
.pa-2 {
  padding: 8px;
}
</style>

<template>
  <v-container>
    <!-- <h1>Courbe de {{ sensorData.name }}</h1> -->
    <v-card>
      <!-- {{ range }} -->
      <!-- {{ series }} -->
      <v-row>
        <v-card-title class="mt-10">
          <v-row>
            <v-col cols="12" xs="6" sm="12" md="12">
              <v-locale-provider locale="fr">
                <v-date-picker
                  v-model="range"
                  color="green"
                  :masks="masks"
                  is-range
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="flex justify-center items-center">
                      <v-row>
                        <v-col cols="12" xs="12" md="4">
                          <input
                            :value="inputValue.start"
                            v-on="inputEvents.start"
                            class="border px-2 py-1 w-10 rounded focus:outline-none focus:border-indigo-300"
                        /></v-col>
                        <!-- <v-col
                          cols="12"
                          xs="12"
                          sm="2"
                          md="2"
                          class="text-center text-sm-left"
                        > -->
                        <v-col cols="12" xs="12" sm="2" md="2">
                          <v-icon class="w-2 h-2 mx-2">
                            mdi-arrow-right
                          </v-icon>
                        </v-col>
                        <v-col cols="12" xs="12" md="4">
                          <input
                            :value="inputValue.end"
                            v-on="inputEvents.end"
                            class="border px-2 py-1 w-28 rounded focus:outline-none focus:border-indigo-300"
                        /></v-col>
                      </v-row>
                    </div>
                  </template>
                </v-date-picker>
              </v-locale-provider>
              <!-- {{ sensorslist_local }} -->
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-btn @click="getSensorsTimeSeries(sensorData._id)"
                >Display</v-btn
              >
              <!-- {{ sensorData._id }} -->
            </v-col>
          </v-row>
        </v-card-title>
      </v-row>

      <v-row>
        <v-col cols="12">
          <!-- <div class="flex mb-2"> -->
          <!-- <v-btn
            class="ma-2"
            variant="text"
            icon="mdi-update"
            color="success"
            size="x-large"
            @click="refreshCharts"
          ></v-btn> -->
          <v-card class="mx-auto" max-width="344">
            <v-divider></v-divider>
            <apexchart
              type="line"
              :options="options"
              :series="series"
            ></apexchart>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
//https://vcalendar.io/datepicker.html
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Axios from "@/_services/caller.service";
import moment from "moment";

export default {
  name: "Single_Chart",
  props: ["sensorData"],
  setup(props) {
    const store = useStore();
    const dateRange = ref(new Date());
    let sensorslist = ref([]);
    const range = ref({
      start: new Date(),
      end: new Date(),
    });

    let rangeCopy = ref({
      start: new Date(),
      end: new Date(),
    });

    const masks = {
      input: "YYYY-MM-DD",
    };

    let series_new = ref([
      {
        name: "serie_1",
        data: [
          [1324508400000, 34],
          [1324594800000, 54],
          [1326236400000, 43],
        ],
      },
    ]);

    let series = ref([]);

    const options = ref({
      chart: {
        id: "Sensor chart",
      },
      title: {
        // text: "La courbe du capteur",
        text: "Chart of sensor",
        align: "left",
      },
      tooltip: {
        theme: "dark", // Garder le tooltip sombre
        style: {
          fontSize: "12px", // Taille de la police
          color: "#ffffff", // Couleur du texte (blanc)
        },
        x: {
          format: "HH:mm:ss",
        },
      },
      annotations: {
        yaxis: [
          {
            y: props["sensorData"].alertSingle.low,
            borderColor: "#FF0000", // Couleur de la ligne min
            // label: {
            //   text: `Min Value: ${props["sensorData"].alertSingle.low}`,
            //   style: {
            //     color: "#FF0000",
            //     background: "#1e1e1e",
            //   },
            // },
          },
          {
            y: props["sensorData"].alertSingle.high, // Valeur maximum
            borderColor: "#00FF00", // Couleur de la ligne max
            // label: {
            //   text: `Max Value: ${props["sensorData"].alertSingle.high}`,
            //   style: {
            //     color: "#00FF00",
            //     background: "#1e1e1e",
            //   },
            // },
          },
        ],
      },
      markers: {
        // pour les point sur la courbe
        size: 5,
      },
      dataLabels: {
        // Affiche la valeur du capteur sur la courbe
        //enabled: true,
      },
      stroke: {
        curve: "smooth",
        lineCap: "butt",
      },
      xaxis: {
        type: "datetime",
        format: "HH:mm:ss",
        labels: {
          datetimeUTC: false, //https://codepen.io/drpuur/pen/ExaQWGx
        },
        //tickAmount: 6,
      },
    });

    const addDataChart = () => {
      series.value.push({
        name: "serie_2",
        data: [
          [1324508400000, 45],
          [1324594800000, 20],
          [1326236400000, 67],
        ],
      });
    };

    const getDateTime = (timestamp) => {
      return new Date(timestamp * 1000).toLocaleString(); //toString();
    };

    const calculateMovingAverage = (data, windowSize) => {
      let movingAverages = [];
      for (let i = 0; i < data.length; i++) {
        const start = Math.max(i - windowSize + 1, 0);
        const end = i + 1;
        const window = data.slice(start, end);
        const avg =
          window.reduce((sum, value) => sum + value[1], 0) / window.length;
        movingAverages.push([window[window.length - 1][0], avg.toFixed(2)]);
      }
      return movingAverages;
    };

    let sensorslist_local = ref([{ name: "", series: [] }]);
    const getSensorsTimeSeriesMulty = async (sensor) => {
      sensorslist_local.value.splice(0, sensorslist_local.value.length);
      const access_token = localStorage.getItem("jwt");
      let sensors = [sensor];

      console.log(sensor);

      // sensors.forEach((elt, index) => {
      //   sensors[index] = elt.substr(0, elt.length - 2);
      //   sensorslist_local.value.push({ name: elt, series: [] });
      // });

      const num = sensor.substr(sensor.length - 1, 1);

      await Axios.post("/api/v1/timeseries/timerange", {
        data: {
          sensorsgroup: sensor,
          startDate: range.value.start,
          endDate: range.value.end,
        },
      })
        .then((res) => {
          try {
            res.data.forEach((element) => {
              if (element.metadata[num].value) {
                const item = [];
                //item[0] = parseInt(element.metadata.timestamp * 1000);
                item[1] = parseFloat(element.metadata[num].value);
                item[0] = new Date(element.timestamp);
                //console.log(item[0]);
                sensorslist_local.value.forEach((sensor) => {
                  if (
                    sensor.name == element.metadata.mac + "_1" ||
                    sensor.name == element.metadata.mac + "_2" ||
                    sensor.name == element.metadata.mac + "_3" ||
                    sensor.name == element.metadata.mac + "_4" ||
                    sensor.name == element.metadata.mac + "_5" ||
                    sensor.name == element.metadata.mac + "_6" ||
                    sensor.name == element.metadata.mac + "_7" ||
                    sensor.name == element.metadata.mac + "_8" ||
                    sensor.name == element.metadata.mac + "_9" ||
                    sensor.name == element.metadata.mac + "_10"
                  ) {
                    sensor.series.push(item);
                  }
                });
              }
            });

            series.value.splice(0, series.value.length);

            sensorslist_local.value.forEach((sensor) => {
              series.value.push({
                name: sensor.name,
                data: sensor.series,
              });
            });
          } catch (error) {
            sensorslist_local.value.forEach((sensor) => {
              series.value.push({
                name: sensor.name,
                data: sensor.series,
              });
            });
            console.log("ATTENTION!!!! " + error);
          }
        })
        .catch((error) => console.log(error));

      sensorsSelected.value.splice(0, sensorsSelected.value.length);
      sensorsSelected2.value = sensorsSelected.value;
      rangeCopy.value = range.value;
    };

    const getSensorsTimeSeries = async (sensor) => {
      sensorslist_local.value.splice(0, sensorslist_local.value.length);
      series.value = [];
      //console.log(sensor);
      const name = sensor;
      let dataSeries = [];
      series.value.length = 0;

      // console.log(props["sensorData"].alertSingle);

      await Axios.post("/api/v1/timeseries/timerange", {
        data: {
          sensorsgroup: sensor,
          startDate: range.value.start,
          endDate: range.value.end,
        },
      })
        .then((res) => {
          try {
            // console.log(res.data[0]);
            res.data.forEach((element) => {
              const item = [];
              item[1] = parseFloat(element.value);
              item[0] = new Date(element.timestamp); //moment(element.timestamp).tz("America/Montreal"); //new Date(element.timestamp);
              dataSeries.push(item);
            });

            series.value.push({
              // name: name,
              name: "Current value",
              data: dataSeries,
            });

            const movingAverage = calculateMovingAverage(dataSeries, 5);
            // console.log(movingAverage);
            // console.log(dataSeries);
            series.value.push({
              name: "Moving Average", // Moyenne mobile
              data: movingAverage,
              stroke: {
                width: 2,
                dashArray: 5, // Lignes en pointillés pour la moyenne mobile
              },
            });
          } catch (error) {
            console.log("ATTENTION!!!! " + error);
          }
        })
        .catch((error) => console.log(error));
    };

    const getSensorsTimeSeries2 = async (sensorId) => {
      sensorslist_local.value.splice(0, sensorslist_local.value.length);
      const access_token = localStorage.getItem("jwt");

      await Axios.post("/api/v1/timeseries/single/" + sensorId, {
        data: {
          startDate: range.value.start,
          endDate: range.value.end,
        },
      })
        .then((res) => {
          try {
            res.data.forEach((element) => {
              if (element.metadata["1"].value) {
                const item = [];
                //item[0] = parseInt(element.metadata.timestamp * 1000);
                item[1] = parseFloat(element.metadata["1"].value);
                item[0] = new Date(element.timestamp);
                //console.log(item[0]);
                sensorslist_local.value.forEach((sensor) => {
                  if (
                    sensor.name == element.metadata.mac + "_1" ||
                    sensor.name == element.metadata.mac + "_2" ||
                    sensor.name == element.metadata.mac + "_3" ||
                    sensor.name == element.metadata.mac + "_4" ||
                    sensor.name == element.metadata.mac + "_5" ||
                    sensor.name == element.metadata.mac + "_6" ||
                    sensor.name == element.metadata.mac + "_7" ||
                    sensor.name == element.metadata.mac + "_8" ||
                    sensor.name == element.metadata.mac + "_9" ||
                    sensor.name == element.metadata.mac + "_10"
                  ) {
                    sensor.series.push(item);
                  }
                });
              }
            });

            series.value.splice(0, series.value.length);

            sensorslist_local.value.forEach((sensor) => {
              series.value.push({
                name: sensor.name,
                data: sensor.series,
              });
            });
          } catch (error) {
            sensorslist_local.value.forEach((sensor) => {
              series.value.push({
                name: sensor.name,
                data: sensor.series,
              });
            });
            console.log("ATTENTION!!!! " + error);
          }
        })
        .catch((error) => console.log(error));

      sensorsSelected.value.splice(0, sensorsSelected.value.length);
      sensorsSelected2.value = sensorsSelected.value;
      rangeCopy.value = range.value;
    };

    let sensorsSelected2 = ref([]);
    const refreshCharts = async () => {
      sensorslist_local.value.splice(0, sensorslist_local.value.length);
      const access_token = localStorage.getItem("jwt");

      sensorsSelected2.value.forEach((elt, index) => {
        sensorsSelected2[index] = elt.substr(0, elt.length - 2);
        sensorslist_local.value.push({ name: elt, series: [] });
      });
      await Axios.post("/api/v1/timeseries/timerange", {
        data: {
          sensorsgroup: sensorsSelected2,
          startDate: rangeCopy.value.start,
          endDate: rangeCopy.value.end,
        },
      })
        .then((res) => {
          try {
            res.data.forEach((element) => {
              if (element.metadata["1"].value) {
                const item = [];
                //item[0] = parseInt(element.metadata.timestamp * 1000);
                item[1] = parseFloat(element.metadata["1"].value);
                item[0] = new Date(element.timestamp);
                //console.log(item[0]);
                sensorslist_local.value.forEach((sensor) => {
                  if (
                    sensor.name == element.metadata.mac + "_1" ||
                    sensor.name == element.metadata.mac + "_2" ||
                    sensor.name == element.metadata.mac + "_3" ||
                    sensor.name == element.metadata.mac + "_4"
                  ) {
                    sensor.series.push(item);
                  }
                });
              }
            });

            series.value.splice(0, series.value.length);

            sensorslist_local.value.forEach((sensor) => {
              series.value.push({
                name: sensor.name,
                data: sensor.series,
              });
            });
          } catch (error) {
            // sensorslist_local.value.forEach((sensor) => {
            //   series.value.push({
            //     name: sensor.name,
            //     data: sensor.series,
            //   });
            // });
            console.log("ATTENTION--!!!! " + error);
          }
        })
        .catch((error) => console.log(error));
    };

    let isGetAll = ref(false);
    const getSensorsGroup = () => {
      console.log("getSensorsGroup");
      isGetAll.value = false;
      store.dispatch("sensorsStore/getAllSensorsGroup");
      store.state.sensorsStore.sensorsGroup;
    };

    const tableDataListe = computed(
      () => store.state.sensorsStore.sensorsGroup
    );

    onMounted(() => {
      console.log("Charts monted");
      getSensorsGroup();
    });

    const admins = [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ];

    const sensorsSelected = ref([]);

    return {
      dateRange,
      series,
      series_new,
      options,
      getSensorsTimeSeries,
      refreshCharts,
      sensorslist_local,
      sensorslist,

      range,
      masks,

      getSensorsGroup,
      tableDataListe,
      admins,
      sensorsSelected,
    };
  },
};
</script>
<style></style>
